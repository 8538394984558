<template>
	<div>
		<HeroSectionTitleOnly title="Latest News" :subheading="subheading"></HeroSectionTitleOnly>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ArticleTileSection :tilenewsitems="news1" addclasses="nopadtop nopadbot"></ArticleTileSection>

		<AdSection adtype="body" backgroundclass="bglightgrey"></AdSection>

		<ArticleTileSection :tilenewsitems="news2" addclasses="nopadbot"></ArticleTileSection>

		<AdSection adtype="midleader" backgroundclass="bglightgrey"></AdSection>

		<ArticleTileSection :tilenewsitems="news3" addclasses="nopadtop"></ArticleTileSection>

		<ShowTileSection :tileshows="store.vuepage.slotprops.showtiles" backgroundclass="bgblack"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";
export default {
	name: "AggregateNews",
	components: {
		HeroSectionTitleOnly,
		FooterSection,
		ArticleTileSection,
		ShowTileSection,
		AdSection,
	},
	mixins: [globalMixin],
	props: {

	},
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		subheading() {
			if(this.store.domain === '2ay') return 'Email your news tip to news@2ay.com.au'
			return false
		},
		vp() {
			return this.store.vuepage
		},
		news1() {
			let ret = []
			if(this.vp && this.store.loading === false) {
				for(let i=0; i<4; i++) {
					if(this.vp.slotprops.newstiles[i]) {
						ret.push(this.vp.slotprops.newstiles[i])
					}
				}
			}
			return ret
		},
		news2() {
			let ret = []
			if(this.vp && this.store.loading === false) {
				for(let i=4; i<12; i++) {
					if(this.vp.slotprops.newstiles[i]) {
						ret.push(this.vp.slotprops.newstiles[i])
					}
				}
			}
			return ret
		},
		news3() {
			let ret = []
			if(this.vp && this.store.loading === false) {
				for(let i=12; i<60; i++) {
					if(this.vp.slotprops.newstiles[i]) {
						ret.push(this.vp.slotprops.newstiles[i])
					}
				}
			}
			return ret
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>