<template>
	<div class="tile-article">
		<a v-if="store.loading === false" :href="jobitem.url" class="tile-linker" :data-targpagetpl="jobitem.pagetemplate">
			<div class="news-card-bottom">
				<p class="tile-heading">{{jobitem.heading}}</p>
				<p class="tile-sub">{{jobitem.subheading}}</p>
			</div>
		</a>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "TileJob",
	components: {},
	props: {
		jobitem: Object
	},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {
		bgimgoverride() {
			if(this.jobitem.background_override_image && this.jobitem.background_override_image.length > 0) {
				return "background-image: url('"+this.jobitem.background_override_image+"') !important;";
			}
			return "";
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.tile-article {
	background: #FFFFFF;
	flex-basis: 23.5%;
	margin-left: 1%;
	margin-right: 1%;
	box-sizing: border-box;
	height: 200px;
	position: relative;
	margin-bottom: 40px;
}
.tile-article:nth-child(4n-3) { /* item 1, 5, 9 etc */
	margin-left: 0;
}
.tile-article:nth-child(4n) { /* item 4, 8, 12 etc */
	margin-right: 0;
}
.tile-linker {
	height: 100%;
	display: block;
	transition: all 0.3s ease-in-out;
	background-color: #FFF;
}
.tile-linker:hover {
	box-shadow: 0 0 15px 10px rgba(0,0,0,0.1);
}
.news-card-bottom {
	padding: 35px;
	overflow: hidden;
}
.tile-heading {
	font-size: 20px;
	font-family: 'Montserrat';
	font-weight: bold;
	margin-bottom: 10px;
}
.tile-sub {
	font-size: 16px;
	font-family: 'Montserrat';
	line-height: 1.4;
}

@media screen and (max-width: 1600px) {
	.news-card-bottom {
		padding: 30px;
	}
	.tile-sub {
		font-size: 14px;
	}
}
@media screen and (max-width: 1050px) {
	.tile-article {
		width: 48%;
		flex-basis: 48%;
		margin-left: unset !important;
		margin-right: unset !important;
	}
}
@media screen and (max-width: 800px) {
	.tile-heading {
		font-size: 16px;
	}
	.tile-sub {
		font-size: 14px;
	}
}
@media screen and (max-width: 640px) {
	.news-card-bottom {
		padding: 30px;
	}
}
@media screen and (max-width: 570px) {
	.tile-article {
		max-width: unset;
		width: 100%;
		flex-basis: 100%;
	}
}
</style>