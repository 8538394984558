<template>
	<div>
		<div class="nav" :class="{'bgdark' : skin === 'am' || skin === 'fm'}">
			<div class="container">
				<div class="logohold">
					<a href="/" data-targpagetpl="home">
						<img :alt="store.globals.domainInfo.meta_title+' logo'" class="logo-image"
							:src="store.urlroot+'/public-static/logos/'+store.domain+'.svg?v=7'"

						/>
					</a>
				</div>
				<div class="navright">
					<div class="on-air-now" v-if="store.globals && store.globals.domainInfo.currentShowName && store.globals.domainInfo.currentShowName.length > 0">
						<p class="oan" style="font-size: 10px; text-transform: uppercase; font-weight: bold;">On Air Now</p>
						<p>{{store.globals.domainInfo.currentShowName}}</p>
					</div>
					<PlayWidget></PlayWidget>
					<div class="menuitems">
						<div v-if="store.metroDomains.indexOf(domain) === -1" class="menuitem">
							<a href="/news" data-targpagetpl="aggregate-news">News</a>
						</div>
						<div class="menuitem">
							<a href="/shows" data-targpagetpl="aggregate-shows">Shows</a>
						</div>
						<div class="menuitem">
							<a href="/win" data-targpagetpl="aggregate-comps">Win</a>
						</div>
						<div class="menuitem">
							<a v-if="store.domain === '3mp'" href="/community" data-targpagetpl="aggregate-events">
								<span>Community</span>
							</a>
							<a v-else href="/events" data-targpagetpl="aggregate-events">
								<span>Events</span>
							</a>
						</div>
					</div>
					<div class="sidebar-toggle-hold">
						<div @click.stop="showSideMenu">
							<span class="menu-ham-text">Menu</span>
							<i class="mdi mdi-menu menu-i"></i>
						</div>
					</div>
				</div>
				<div id="sidebar-nav" class="sidebar-bg" :class="{shown : sidebarShown}">
					<div class="sidebar-body">
						<div @click.stop="hideSideMenu" class="sidebar-close-holder">
							<i class="mdi mdi-close text-white"></i>
						</div>

						<SearchBox></SearchBox>

						<div class="text-white" style="margin-top: 20px;">
							<a v-if="store.metroDomains.indexOf(store.domain) === -1" href="/news" class="side-menu-link tablet-only" data-targpagetpl="aggregate-news">News</a>
							<a href="/shows" class="side-menu-link tablet-only" data-targpagetpl="aggregate-shows">Shows</a>
							<a href="/win" class="side-menu-link tablet-only" data-targpagetpl="aggregate-comps">Win</a>
							<a v-if="domain === '3mp'" href="/community" class="side-menu-link tablet-only" data-targpagetpl="aggregate-events">Community</a>
							<a v-else href="/events" class="side-menu-link tablet-only" data-targpagetpl="aggregate-events">Events</a>

							<a href="/forms/contact-us" class="side-menu-link" data-targpagetpl="public-form">Contact</a>
							<a v-if="store.metroDomains.indexOf(store.domain) === -1" href="/forms/report-news" class="side-menu-link" data-targpagetpl="public-form">Send News Tip</a>
							<a href="/forms/advertising-enquiry" class="side-menu-link" data-targpagetpl="public-form">Advertise</a>
							<a v-if="domainsWithDirectory.includes(domain)" href="/directory" class="side-menu-link" data-targpagetpl="directory">Open For Business</a>
							<a v-if="domain === '3ha' || domain === 'mixx889'" href="/directories/tradies-club" class="side-menu-link" data-targpagetpl="directory">Tradies Club</a>
							<a v-if="domain === '3cs' || domain === 'mixx1063'" href="/articles/fab-foodies" class="side-menu-link" data-targpagetpl="article-news">Fab Foodies</a>
							<a v-if="domain === '3sh' || domain === 'mixx1077'" href="/pdf-holders/swanhill-tradies-club" class="side-menu-link" data-targpagetpl="pdf-holder">Tradies Club</a>
							<a v-if="domain === '3ha' || domain === 'mixx889'" href="/directories/local-directory" class="side-menu-link" data-targpagetpl="directory">Local Directory</a>
	<!--						<a v-if="domain === '3yb'" href="/directories/3ybfm-trades-directory/" class="side-menu-link" data-targpagetpl="directory">Tradies Directory</a>-->
							<a href="/forms/submit-event" class="side-menu-link" data-targpagetpl="public-form">Add An Event</a>
	<!--						<a v-if="domain === 'mixx1063'" href="/shows/cdfl-live" class="side-menu-link" data-targpagetpl="article-shows">CDFL Live</a>-->
							<a v-if="domain === '3sh' || domain === 'mixx1077'" href="/articles/footy-zone" class="side-menu-link" data-targpagetpl="article-news">Footyzone</a>
							<a v-if="domain === '2qn' || domain === 'edqn'" href="/pdf-holders/southern-riverina-trusty-tradies" class="side-menu-link" data-targpagetpl="pdf-holder">Trusty Tradies</a>
							<a v-if="store.metroDomains.indexOf(store.domain) === -1" href="/ace-jobs" class="side-menu-link" data-targpagetpl="simple-page">Jobs</a>
							<a href="/codes-of-conduct" class="side-menu-link" data-targpagetpl="simple-page">Codes Of Conduct</a>
							<a v-if="domain === '3mp'" :href="'/'+store.globals.domainInfo.subscribe_link" class="side-menu-link" data-targpagetpl="article-competition">Easy Club</a>
							<a v-if="domain === '3mp'" href="/directories/peninsulapicks" class="side-menu-link" data-targpagetpl="directory">Peninsula Picks</a>
							<a v-if="domain === '3mp'" href="/podcasts" class="side-menu-link" data-targpagetpl="aggregate-podcasts">Podcasts</a>
							<a v-if="domain !== '3mp'" :href="'/'+store.globals.domainInfo.subscribe_link" class="side-menu-link" data-targpagetpl="article-competition">Subscribe</a>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="navpad"></div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import PlayWidget from "@/components/PlayWidget";
import SearchBox from "@/components/SearchBox.vue";
export default {
	name: "NavBar",
	components: {
		SearchBox,
		PlayWidget
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			domainsWithDirectory: ['3sh','mixx1077'],
		}
	},
	computed: {
		domain() {
			return this.store.domain;
		},
		skin() {
			return this.getSkin()
		},
		sidebarShown: {
			get() {
				return this.store.sideMenuIsOpen
			},
			set(val) {
				this.store.sideMenuIsOpen = val
			}
		}
	},
	methods: {
		showSideMenu() {
			this.sidebarShown = true
		},
		hideSideMenu() {
			this.sidebarShown = false
		},
	},
	watch: {

	},
	mounted() {

	}
}
</script>
<style>

</style>
<style scoped>
#sidebar-nav {
	position: fixed;
	top: 0;
	right: -320px;
	height: 100%;
	overflow-y: auto;
	width: 320px;
	transition: transform 0.3s ease-in-out;
}
.sidebar-bg {
	background-color: #3F1A47;
}
#sidebar-nav.shown {
	transform: translateX(-320px);
	box-shadow: 0 0 10px 3px rgb(0 0 0 / 20%);
}
.sidebar-close-holder {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
}
.fm .sidebar-bg {
	background-color: #3F1A47 !important;
}
.am .sidebar-bg {
	background-color: #023B74 !important;
}
.mp .sidebar-bg {
	background-color: #25BEC8 !important;
}
.magic .sidebar-bg {
	background-color: #F06312 !important;
}
.sidebar-body {
	width: 86%;
	margin: 10px auto;
	position: relative;
}
.nav {
	height: 100px;
	position: fixed;
	width: 100%;
	z-index: 20;
}
.nav .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.am .nav .container,
.fm .nav .container {
	height: 100%;
}
.mp .nav,
.magic .nav {
	box-shadow: 0 0 10px 3px rgb(0 0 0 / 20%);
}
.navpad {
	height: 100px;
}
.logohold {
	max-width: 300px;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.on-air-now {
	text-align: right;
	width: 200px;
	/*background-color: pink;*/
	padding: 5px 10px 0 10px;
	color: #FFF;
}
.on-air-now p {
	color: #FFF;
}
.mp .on-air-now p,
.magic .on-air-now p {
	color: #000;
}
.mp p.oan {
	color: #25BEC8;
}
.magic p.oan {
	color: #F06312;
}
/** image sizings for logos **/
.logo-image {
	height: 30px;
	max-height: 30px;
}
.mp .logo-image,
.magic .logo-image {
	height: 100px;
	max-height: unset;
	margin-top: 16px;
}
.mp .navright,
.magic .navright {
	margin-top: -20px; /* must be half of any logo margin top */
}
.d3ha .logo-image,
.dg1242 .logo-image{
	height: 60px;
	max-height: unset;
}
.d3yb .logo-image {
	height: 40px;
	max-height: unset;
}
/** END image sizings for logos **/
.navright {
	height: 100%;
	display: flex;
	align-items: center;
}
.menuitems {
	display: flex;
}
.menuitem, .sidebar-toggle-hold {
	color: #FFF;
	text-transform: uppercase;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	/*margin: 0 20px;*/
}
.mp .menuitem, .mp .sidebar-toggle-hold,
.magic .menuitem, .magic .sidebar-toggle-hold {
	color: #000;
}
.menuitem a, .sidebar-toggle-hold > div {
	padding: 10px 20px;
	border-radius: 20px;
	transition: background-color 0.3s ease-in-out;
}
.mp .menuitem a:hover, .mp .sidebar-toggle-hold > div:hover,
.magic .menuitem a:hover, .magic .sidebar-toggle-hold > div:hover {
	background-color: #EEE;
}
.am .menuitem a:hover, .am .sidebar-toggle-hold > div:hover {
	background-color: #204D78;
}
.fm .menuitem a:hover, .fm .sidebar-toggle-hold > div:hover {
	background-color: #4B2253;
}
.sidebar-toggle-hold {
	margin-left: 80px;
}
.menu-ham-text {
	margin-right: 10px;
}
.menu-i {
	font-size: 14px;
}
.side-menu-link {
	display: block;
	padding: 10px 20px;
	font-family: 'Roboto';
	font-size: 15px;
	transition: background-color 0.2s ease-in-out;
	border-radius: 20px;
}
.mp .side-menu-link:hover {
	background-color: #45DEE8;
}
.magic .side-menu-link:hover {
	background-color: #F08332;
}
.fm .side-menu-link:hover {
	background-color: #4B2253;
}
.am .side-menu-link:hover {
	background-color: #204D78;
}
.tablet-only {
	display: none;
}

@media screen and (max-width: 1350px) {
	.sidebar-toggle-hold {
		margin-left: 0;
	}
	.menuitem a, .sidebar-toggle-hold > div {
		padding: 10px 15px;
	}
}
@media screen and (max-width: 1150px) {
	.on-air-now {
		display: none;
	}
}
@media screen and (max-width: 1000px) {
	.menu-ham-text {
		display: none;
	}
	.menu-i {
		font-size: 20px;
	}
}
@media screen and (max-width: 900px) {
	.menuitem {
		display: none;
	}
	.tablet-only {
		display: block;
	}
	.menu-ham-text {
		display: unset;
	}
	.menu-i {
		font-size: 14px;
	}
}
@media screen and (max-width: 768px) {
	.menu-ham-text {
		display: none;
	}
	.menu-i {
		font-size: 20px;
	}
}
@media screen and (max-width: 580px) {
	.nav {
		height: 70px;
		position: fixed;
		width: 100%;
		z-index: 20;
	}
	.navpad {
		height: 70px;
	}
	.logohold {
		max-width: 200px;
	}
	/** image sizings for logos **/
	.logo-image {
		height: 30px;
		max-height: 30px;
	}
	.mp .logo-image,
	.magic .logo-image {
		height: 70px;
		max-height: unset;
		margin-top: 10px;
	}
	/** END image sizings for logos **/
	.mp .navright,
	.magic .navright {
		margin-top: -5px; /* must be half of any logo margin top */
	}
}
</style>
